import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from "react";
import axios from 'axios';
import {
    Row,
    Col,
    Card,
    Alert,
    CardBody,
    Button,
    Modal, ModalBody, Label
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import Utils from '../Utility'
import avatar from "../../assets/images/small/sample.png"
import { post, upload, put, get } from '../../helpers/api_helper';
import { toast } from 'react-toastify';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import Resizer from "react-image-file-resizer";
import Multiselect from 'multiselect-react-dropdown';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

const AddContent = props => {
    const { user, data, setLoading, role } = props;
    const [file, setFile] = useState(null);
    const imageInput = useRef();
    const imageInputStep = useRef();
    const cropper = useRef();
    const [tags, setTags] = useState([]);
    const [cat, setCat] = useState([]);
    const [descContent, setDescContent] = useState(null);
    const [desc, setDesc] = useState("");
    const [cropperModal, setCropperModal] = useState(false);
    const [currentData, setCurrentData] = useState(null);
    const [selectedTags, setSelectedTags] = useState([]);
    const [contentType, setContentType] = useState("");
    const [video, setVideo] = useState(null);
    const [audio, setAudio] = useState(null);
    const [infoData, setInfoData] = useState([{}]);
    const [currenFile, setCurrentFile] = useState(null);
    const [currentFileType, setCurrentFileType] = useState("file");
    const [stepIndex, setStepIndex] = useState(0);
    const [selectedLang, setSelectedLang] = useState([]);
    // const [progress, setProgress] = useState(0);
    // const [progLoad, setProgLoad] = useState(false);

    async function handleValidSubmit(event, values) {
        try {
            if (data) {
                setLoading(true);
                let lang = [];
                selectedLang?.forEach((item) => {
                    if (item?.descContent)
                        item.descContent = undefined;
                    lang.push(item);
                });
                let body = {
                    mailId: data?._id,
                    ...values,
                    alterLang: lang,
                    html: desc,
                    token: user?.token,
                }
                console.log('body after image upload', body);
                put("/mail", body)
                    .then((json) => {
                        console.log('response from adding comp', json);
                        setLoading(false);
                        if (json.statusCode == 200) {
                            console.log('property added');
                            toast.success(json?.message);
                            props.close(false);
                        } else {
                            toast.error(json?.error);
                        }
                    })
                    .catch(error => {
                        console.error('error while adding oppor', error);
                        toast.error(JSON.stringify(error));
                        setLoading(false);
                    });
            } else {
                setLoading(true);
                let lang = [];
                selectedLang?.forEach((item) => {
                    if (item?.descContent)
                        item.descContent = undefined;
                    lang.push(item);
                });
                let body = {
                    ...values,
                    alterLang: lang,
                    html: desc,
                    token: user?.token,
                }
                post("/mail", body)
                    .then((json) => {
                        console.log('response from adding product', json);
                        setLoading(false);
                        if (json.statusCode == 200) {
                            toast.success(json?.message);
                            props.close(false);
                        } else {
                            toast.error(json?.error);
                        }
                    })
                    .catch(error => {
                        console.error('error while adding oppor', error);
                        toast.error(JSON.stringify(error));
                        setLoading(false);
                    })
            }
        } catch (error) {
            setLoading(false);
            console.log('error while submitting API', error);
        }
    }

    useEffect(() => {
        if (data) {
            if (data?.html) {
                const contentBlock = htmlToDraft(data?.html);
                if (contentBlock) {
                    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                    const editorState = EditorState.createWithContent(contentState);
                    setDescContent(editorState);
                    setDesc(data?.html);
                }
            }
            if (data?.alterLang?.length) {
                data?.alterLang?.forEach((lang) => {
                    user?.languages?.forEach((allLang) => {
                        if (allLang?.code == lang?.code)
                            lang.lang = allLang?.title;
                    });
                    if (lang?.html) {
                        const contentBlock = htmlToDraft(lang?.html);
                        if (contentBlock) {
                            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                            const editorState = EditorState.createWithContent(contentState);
                            lang.descContent = editorState;
                        }
                    }
                });
                setSelectedLang(data?.alterLang);
            }
        }
    }, [data, user]);

    useEffect(() => {
        if (contentType == "Info") {
            let tempInfo = Object.assign([], infoData);
            selectedLang?.map((lang) => {
                tempInfo.forEach(item => {
                    if (item.alterLang?.length) {
                        let found = item?.alterLang?.findIndex(x => x?.code == lang?.code);
                        if (found == -1)
                            item.alterLang.push({ code: lang?.code, lang: lang?.lang, desc: "" });
                    } else
                        item.alterLang = [{ code: lang?.code, lang: lang?.lang, desc: "" }];
                });
            });
            setInfoData(tempInfo);
        }
    }, [contentType])

    const onEditorStateChange = (editorState) => {
        const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        setDesc(html);
        setDescContent(editorState);
    }

    const onEditorStateChangeAlter = (editorState, i) => {
        const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        let alter = Object.assign([], selectedLang);
        alter[i].descContent = editorState;
        alter[i].html = html;
        setSelectedLang(alter);
    }

    const handleSelectLang = (code, lang) => {
        if (code != "en") {
            let temp = Object.assign([], selectedLang);
            let already = temp.findIndex(x => x.code == code);
            if (already == -1) {
                temp.push({ code: code, title: "", lang });
                if (contentType == "Info") {
                    let tempInfo = Object.assign([], infoData);
                    tempInfo.forEach(item => {
                        if (item.alterLang?.length)
                            item.alterLang.push({ code, lang, desc: "" });
                        else
                            item.alterLang = [{ code, lang, desc: "" }];
                    });
                    setInfoData(tempInfo);
                }
            } else {
                temp.splice(already, 1);
                if (contentType == "Info") {
                    let tempInfo = Object.assign([], infoData);
                    tempInfo.forEach(item => {
                        let found = item.alterLang.findIndex(x => x.code == code);
                        if (found != -1)
                            item.alterLang.splice(found, 1);
                    });
                    setInfoData(tempInfo);
                }
            }
            // console.log("temp", temp);
            setSelectedLang(temp);
        }
    }

    const handleAlterTitle = (index, key, val) => {
        let temp = Object.assign([], selectedLang);
        temp[index][key] = val;
        setSelectedLang(temp);
    }

    return (
        <React.Fragment>
            <div>
                <Row>
                    <Col lg="12">
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col lg={11} md={11}>
                                        <h4 className="card-title mb-4"><b>{data ? 'Update Mail' : 'Add New Mail'}</b></h4>
                                    </Col>
                                    <Col lg={1} md={1}>
                                        <div className="text-center" style={{ marginLeft: 'auto' }}>
                                            <Button color="primary" onClick={() => { props.setCurrentData(null); props?.close(false) }} style={{ backgroundColor: Utils.themeColor, border: 'none' }}>
                                                Close
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Card>
                    <CardBody>
                        <AvForm
                            className="form-horizontal"
                            onValidSubmit={(e, v) => {
                                handleValidSubmit(e, v)
                            }}
                        >
                            <Row>
                                <Col md={2}>
                                    <div className="form-group mb-4">
                                        <AvField
                                            name="event"
                                            label="Event *"
                                            className="form-control"
                                            type="select"
                                            value={data?.event}
                                            required
                                        >
                                            <option value={""} >Select Mail Event</option>
                                            <option value={"Register"}>Register</option>
                                            <option value={"Paid"}>Get Paid</option>
                                            <option value={"Reminder"}>Payment Reminder</option>
                                            <option value={"Feedback"}>10 Days Feedback</option>
                                            <option value={"TTMApproved"}>Talk to me Approve</option>
                                            <option value={"TTMReject"}>Talk to me Reject</option>
                                            <option value={"KnowYourself"}>Know Yourself Result with Attachment</option>
                                        </AvField>
                                    </div>
                                </Col>
                                <Col md={10}>
                                    <div className="form-group mb-4">
                                        <AvField
                                            name="subject"
                                            label="Subject *"
                                            value={data?.subject}
                                            className="form-control"
                                            placeholder="Enter Mail Subject"
                                            type="text"
                                            required
                                        />
                                    </div>
                                </Col>
                                <Col md={12}>
                                    <div className="form-group mb-4">
                                        <label>Description*</label>
                                        <Editor
                                            toolbarClassName="toolbarClassName"
                                            wrapperClassName="wrapperClassName"
                                            editorClassName="editorClassName"
                                            onEditorStateChange={(e) => onEditorStateChange(e)}
                                            editorState={descContent}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Label>Alternative Languages</Label>
                            <Row>
                                <div className='lang-checks mb-3'>
                                    {user?.languages?.map((item) => (
                                        <div className='check-group'>
                                            <label for={item?.title} class="container">
                                                {item?.title}
                                                <input type="checkbox" name={item?.title} id={item?.title} checked={selectedLang.findIndex(x => x?.code == item?.code) != -1 || item?.code == "en"} onChange={(e) => handleSelectLang(item?.code, item?.title)} />
                                                <span class="checkmark"></span>
                                            </label>
                                        </div>
                                    ))}
                                </div>
                                {selectedLang?.map((item, index) => (
                                    <Row>
                                        <Col md={6}>
                                            <div className="mb-3">
                                                <AvField
                                                    name={"subject" + item?.lang}
                                                    label={"Subject in " + item?.lang}
                                                    className="form-control"
                                                    placeholder={"Enter title in " + item?.lang}
                                                    type="text"
                                                    onChange={(e) => handleAlterTitle(index, "subject", e.target.value)}
                                                    required
                                                    value={item?.title}
                                                />
                                            </div>
                                        </Col>
                                        <Col md={12}>
                                            {selectedLang?.map((item, index) => (
                                                <div className="form-group mb-4">
                                                    <label>Description in {item?.lang}*</label>
                                                    <Editor
                                                        toolbarClassName="toolbarClassName"
                                                        wrapperClassName="wrapperClassName"
                                                        editorClassName="editorClassName"
                                                        onEditorStateChange={(e) => onEditorStateChangeAlter(e, index)}
                                                        editorState={item.descContent}
                                                    />
                                                </div>
                                            ))}
                                        </Col>
                                    </Row>
                                ))}
                            </Row>
                            <div className="text-center mt-4">
                                <Button type="reset" onClick={() => { props.setCurrentData(null); props?.close(false) }} style={{ border: 'none', backgroundColor: Utils.themeColor, marginRight: 30 }}>
                                    Cancel
                                </Button>
                                {data ?
                                    <Button type="submit" style={{ border: 'none', backgroundColor: Utils.themeColor }}>
                                        Update Mail
                                    </Button>
                                    :
                                    <Button type="submit" style={{ border: 'none', backgroundColor: Utils.themeColor }}>
                                        Add Mail
                                    </Button>
                                }
                            </div>
                        </AvForm>
                    </CardBody>
                </Card>
            </div>
        </React.Fragment >
    )
}

AddContent.propTypes = {

}

const mapStatetoProps = state => {
    const { user } = state.Profile;
    return { user }
}

export default withRouter(
    connect(mapStatetoProps, {})(AddContent)
)
